import { SolutionFeatures } from 'core/api/types/features';
import { setSolutionFeatures } from 'core/features/featuresSlice';
import { browserStorage } from 'state/helpers/browserStorage';
import { AvailableTheme, setTheme } from 'core/ui/uiSlice';
import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { Environment, getConfigByDomain } from './environmentVariables';
import { tr } from 'translations';
import { ExpenseType } from 'core/api/types/expenses';

export const solutionBrowserStorageKey = 'solution';

export const currencies = ['GBP', 'EUR'];

export type Currency = (typeof currencies)[number];

export const solutions = [
  'cardlaySapconcur',
  'cardlayDemo',
  'lunadis',
  'cardlayExpense',
  'avidspend',
  'conduiitConnect',
] as const;

export type Solution = (typeof solutions)[number];

export type SolutionSettings = {
  defaultCurrency: Currency;
  supportLink: string;
  helpdeskUrl: string;
  appStoreLinks?: AppStoreLinks;
  termsAndConditionsDocumentUrl: string;
  privacyPolicyDocumentUrl: string;
  productName: string;
  releaseNotesUrl?: string;
};

type AppStoreLinks = {
  android: string;
  ios: string;
};

type Metadata = {
  title: string;
  description: string;
  previewImage: string;
  faviconSvg: string;
  faviconXIcon: string;
  manifest: string;
};

export type PersonalDetailsFields =
  | 'salutation'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'cityOfBirth'
  | 'birthDate'
  | 'externalId'
  | 'street'
  | 'additionalInfo'
  | 'country'
  | 'postalCode'
  | 'city'
  | 'state'
  | 'taxId'
  | 'taxCountry'
  | 'termsAndConditions';

export type PersonalDetailsSettings = {
  conditionalFields?: PersonalDetailsFields[];
  disabledFields?: PersonalDetailsFields[];
};

export type SolutionConfiguration = {
  configuredExpensesType?: ExpenseType[];
  theme: AvailableTheme;
  id: string;
  solution: Solution;
  features: SolutionFeatures;
  metadata: Metadata;
  settings: SolutionSettings;
  personalDetailsSettings?: PersonalDetailsSettings;
};

export type DomainSolutions =
  | `${string}:${Solution}`
  | `${string}:${Solution},${string}:${Solution}`;

export function isValidSolution(
  possibleSolution: Solution
): possibleSolution is Solution {
  return [...solutions].includes(possibleSolution);
}

export const getSolutionConfigurationFromSolutionName = (
  solution: Solution
): SolutionConfiguration => {
  switch (solution) {
    case 'cardlaySapconcur':
      return {
        theme: 'light',
        id: '4a3f7163-60c4-4be9-b7dc-0b691a08da77',
        solution,
        features: {
          showLoginFooterLogo: true,
          showLoginForm: false,
          showSetPassword: false,
          showForgotPassword: false,
          showDownloadApps: false,
          deviceMonitoring: false,
          termsAndConditionsConfirmationRequired: false,
        },
        metadata: {
          title: tr('web_cardlaysapconcur_metadata_title'),
          description: tr('web_cardlaysapconcur_metadata_description'),
          previewImage: '/cardlaySapconcur/og.jpg',
          faviconSvg: '/cardlaySapconcur/favicon.svg',
          faviconXIcon: '/cardlaySapconcur/favicon.ico',
          manifest: '/cardlaySapconcur/manifest.json',
        },
        settings: {
          productName: 'Cardlay Pay',
          defaultCurrency: 'GBP',
          supportLink: 'mailto:helpdesk@cardlay.com',
          termsAndConditionsDocumentUrl:
            'https://cardlay.com/assets/Cardlay-Pay-Terms.pdf',
          privacyPolicyDocumentUrl: '/privacypolicy.pdf',
          helpdeskUrl: 'https://cardlayhelp.zendesk.com/',
        },
        configuredExpensesType: [
          'CORPORATE',
          'REIMBURSEMENT',
          'OUT_OF_POCKET',
          'PERSONAL',
        ],
      };

    case 'cardlayExpense':
      return {
        theme: 'light',
        id: '2d593db6-0569-40c5-997c-bc1d2c472d59',
        solution,
        features: {
          showLoginFooterLogo: false,
          showLoginForm: true,
          showSetPassword: true,
          showForgotPassword: true,
          showDownloadApps: true,
          deviceMonitoring: false,
          termsAndConditionsConfirmationRequired: false,
        },
        metadata: {
          title: tr('web_cardlayexpense_metadata_title'),
          description: tr('web_cardlayexpense_metadata_description'),
          previewImage: '/cardlayExpense/og.jpg',
          faviconSvg: '/cardlayExpense/favicon.svg',
          faviconXIcon: '/cardlayExpense/favicon.ico',
          manifest: '/cardlayExpense/manifest.json',
        },
        settings: {
          productName: 'Cardlay Expense',
          defaultCurrency: 'GBP',
          supportLink: 'mailto:helpdesk@cardlay.com',
          termsAndConditionsDocumentUrl:
            'https://expense.cardlay.io/cardlayExpense/terms_of_use_cardlay_expense_ec.pdf',
          privacyPolicyDocumentUrl: '/privacypolicy.pdf',
          helpdeskUrl: 'https://cardlayhelp.zendesk.com/',
          releaseNotesUrl: 'https://expense.cardlay.com/releases',
          appStoreLinks: {
            ios: 'https://apps.apple.com/app/cardlay-expense/id6448982202',
            android:
              'https://play.google.com/store/apps/details?id=com.cardlay.expense',
          },
        },
        personalDetailsSettings: {
          conditionalFields: ['externalId'],
          disabledFields: ['externalId'],
        },
        configuredExpensesType: [
          'CORPORATE',
          'REIMBURSEMENT',
          'OUT_OF_POCKET',
          'PERSONAL',
        ],
      };

    case 'cardlayDemo':
      return {
        theme: 'light',
        id: '6cab71f0-7c01-4202-a0c9-e12a8201a778',
        solution,
        features: {
          showLoginFooterLogo: false,
          showLoginForm: true,
          showSetPassword: true,
          showForgotPassword: true,
          showDownloadApps: true,
          deviceMonitoring: false,
          termsAndConditionsConfirmationRequired: false,
        },
        metadata: {
          title: tr('web_cardlaysapconcur_metadata_title'),
          description: tr('web_cardlaysapconcur_metadata_description'),
          previewImage: '/cardlayDemo/og.jpg',
          faviconSvg: '/cardlayDemo/favicon.svg',
          faviconXIcon: '/cardlayDemo/favicon.ico',
          manifest: '/cardlayDemo/manifest.json',
        },
        settings: {
          productName: 'Cardlay Pay',
          defaultCurrency: 'USD',
          supportLink: 'mailto:helpdesk@cardlay.com',
          termsAndConditionsDocumentUrl:
            'https://cardlay.com/assets/Cardlay-Pay-Terms.pdf',
          privacyPolicyDocumentUrl: '/privacypolicy.pdf',
          helpdeskUrl: 'https://cardlayhelp.zendesk.com/',
        },
        configuredExpensesType: [
          'CORPORATE',
          'REIMBURSEMENT',
          'OUT_OF_POCKET',
          'PERSONAL',
        ],
        personalDetailsSettings: {
          conditionalFields: [
            'additionalInfo',
            'city',
            'country',
            'postalCode',
            'state',
            'street',
            'termsAndConditions',
          ],
          disabledFields: ['country'],
        },
      };

    case 'avidspend':
      return {
        theme: 'avid',
        id: 'a4a89d04-3d3c-47c3-9886-a89442d60203',
        solution,
        features: {
          showLoginFooterLogo: false,
          showLoginForm: true,
          showSetPassword: true,
          showForgotPassword: true,
          showDownloadApps: true,
          deviceMonitoring: false,
          termsAndConditionsConfirmationRequired: false,
        },
        metadata: {
          title: tr('web_avidspend_metadata_title'),
          description: tr('web_avidspend_metadata_description'),
          previewImage: '/avidspend/og.jpg',
          faviconSvg: '/avidspend/favicon.svg',
          faviconXIcon: '/avidspend/favicon.ico',
          manifest: '/avidspend/manifest.json',
        },
        settings: {
          productName: 'Avid Spend',
          defaultCurrency: 'USD',
          supportLink: 'mailto:helpdesk@cardlay.com',
          termsAndConditionsDocumentUrl:
            'https://www.avidxchange.com/terms-and-conditions/spend',
          privacyPolicyDocumentUrl:
            'https://www.avidxchange.com/terms-and-conditions/spend',
          helpdeskUrl: 'https://cardlayhelp.zendesk.com/',
          appStoreLinks: {
            ios: 'https://apps.apple.com/app/cardlay-expense/id6448982202',
            android:
              'https://play.google.com/store/apps/details?id=com.avidxchange.avidspend',
          },
        },
        personalDetailsSettings: {
          conditionalFields: [
            'additionalInfo',
            'city',
            'country',
            'postalCode',
            'state',
            'street',
            'termsAndConditions',
          ],
          disabledFields: ['country'],
        },
        configuredExpensesType: ['CORPORATE', 'OUT_OF_POCKET', 'PERSONAL'],
      };

    case 'conduiitConnect':
      return {
        theme: 'conduiit',
        id: 'cc566e5c-2684-446f-844f-96f586d36c44',
        solution,
        features: {
          showLoginFooterLogo: false,
          showLoginForm: true,
          showSetPassword: true,
          showForgotPassword: true,
          showDownloadApps: true,
          deviceMonitoring: false,
          termsAndConditionsConfirmationRequired: false,
        },
        metadata: {
          title: tr('web_conduiit_connect_metadata_title'),
          description: tr('web_conduiit_connect_metadata_description'),
          previewImage: '/conduiitConnect/og.jpg',
          faviconSvg: '/conduiitConnect/favicon.svg',
          faviconXIcon: '/conduiitConnect/favicon.ico',
          manifest: '/conduiitConnect/manifest.json',
        },
        settings: {
          productName: 'Conduiit Connect',
          defaultCurrency: 'USD',
          supportLink: '',
          termsAndConditionsDocumentUrl: '',
          privacyPolicyDocumentUrl: '',
          helpdeskUrl: '',
          appStoreLinks: {
            ios: '',
            android: '',
          },
        },
        personalDetailsSettings: {
          conditionalFields: [
            'additionalInfo',
            'city',
            'country',
            'postalCode',
            'state',
            'street',
            'termsAndConditions',
          ],
          disabledFields: ['country'],
        },
        configuredExpensesType: [
          'CORPORATE',
          'REIMBURSEMENT',
          'OUT_OF_POCKET',
          'PERSONAL',
        ],
      };
    case 'lunadis':
      return {
        theme: 'lunadis',
        id: 'a3aa922c-8260-4f1b-9c83-6a5767326a0f',
        solution,
        features: {
          showLoginForm: true,
          showSetPassword: true,
          showForgotPassword: true,
          showDownloadApps: true,
          showLoginFooterLogo: false,
          deviceMonitoring: true,
          termsAndConditionsConfirmationRequired: true,
        },
        metadata: {
          title: tr('web_lunadis_metadata_title'),
          description: tr('web_lunadis_metadata_description'),
          previewImage: '/lunadis/og.jpg',
          faviconSvg: '/lunadis/favicon.svg',
          faviconXIcon: '/lunadis/favicon.ico',
          manifest: '/lunadis/manifest.json',
        },
        settings: {
          productName: 'Lunadis Pay',
          defaultCurrency: 'EUR',
          supportLink: 'mailto:support@info.lunadispay.com',
          termsAndConditionsDocumentUrl:
            'https://www.lunadis.com/assets/documents/de_agb.pdf',
          privacyPolicyDocumentUrl:
            'https://www.lunadis.com/assets/documents/de_datenschutz.pdf',
          helpdeskUrl: 'https://www.lunadis.com/de/footer/faq/',
          appStoreLinks: {
            ios: 'https://apps.apple.com/app/lunadis-pay/id1667492250',
            android:
              'https://play.google.com/store/apps/details?id=com.cardlay.pay.lunadis',
          },
        },
        personalDetailsSettings: {
          conditionalFields: [
            'salutation',
            'cityOfBirth',
            'city',
            'country',
            'postalCode',
            'street',
            'taxCountry',
            'taxId',
          ],
        },
        configuredExpensesType: ['CORPORATE', 'OUT_OF_POCKET', 'PERSONAL'],
      };

    default: {
      // we never want to end here - check that you have added a case with all
      // solutions.
      throw new Error(`invalidSolution: ${solution as string}`);
    }
  }
};

export function getSolutionFromBrowserStorage(environment: Environment) {
  if (environment === 'development') {
    const solutionFromLocalStorage = browserStorage.getItem(
      solutionBrowserStorageKey
    ) as Solution;

    if (isValidSolution(solutionFromLocalStorage)) {
      return solutionFromLocalStorage;
    }
  }
  return null;
}

export function getSolutionForThisDomain(
  domain: string,
  domainSolutionList: DomainSolutions,
  environment: Environment
): Solution {
  const solutionFromBrowserStorage = getSolutionFromBrowserStorage(environment);

  if (solutionFromBrowserStorage) {
    return solutionFromBrowserStorage;
  }

  const solution = getConfigByDomain<Solution, DomainSolutions>(
    domain,
    domainSolutionList
  );

  if (!solution || !isValidSolution(solution)) {
    return 'cardlaySapconcur';
  }
  return solution;
}

export const setSolutionConfiguration = (
  // TODO: remove dispatch
  dispatch: Dispatch<AnyAction>,
  solution: Solution
) => {
  const { features: solutionFeatures, theme } =
    getSolutionConfigurationFromSolutionName(solution);

  dispatch(
    setSolutionFeatures({
      solutionFeatures,
    })
  );

  dispatch(
    setTheme({
      theme,
    })
  );

  if (window.Cypress) {
    const solutionFeaturesFromSessionStorage = JSON.parse(
      sessionStorage.getItem('solutionFeatures') || '{}'
    ) as Partial<SolutionFeatures>;
    const testSolutionFeatures = {
      ...solutionFeatures,
      ...solutionFeaturesFromSessionStorage,
    } satisfies SolutionFeatures;

    dispatch(
      setSolutionFeatures({
        solutionFeatures: testSolutionFeatures,
      })
    );
    sessionStorage.removeItem('solutionFeatures');
  }
};
