import { AppConfig, config } from './config';
import {
  DomainSolutions,
  getSolutionForThisDomain,
} from './solutionConfiguration';

export const environments = ['production', 'development'] as const;
export type Environment = (typeof environments)[number];

export type configMappedByDomain<configValue extends string = string> =
  | `${string}:${configValue}`
  | `${string}:${configValue},${string}:${configValue}`;

export type EnvironmentVariables = {
  AG_GRID_LICENSE: string;
  API_ENDPOINT: string;
  CONSOLIDATED_API_ENDPOINT: string;
  APP_NAME: string;
  CARDLAY_CLIENT_ID: string;
  CONCUR_OAUTH_API_URL: string;
  LOGGING_DEBUG_ENABLED: string;
  LOGGING_ENABLED: string;
  MIXPANEL_TOKEN: string;
  CONCUR_CLIENT_ID: string;
  SENTRY_DSN: string;
  VERSION: string;
  SEON_SDK_HOST: string;
  SEON_SDK_SOURCE_URL: string;
  SEON_SDK_SCRIPT_ID: string;
  SOLUTION: DomainSolutions;
  GOOGLE_MAPS_API_KEY: configMappedByDomain<string>;
};

// This template literal type is used to prefix the environment variables. We
// name them e.g. 'REACT_APP_VERSION' locally and 'CONFIG_APP_VERSION' in
// production.
type EnvVar<Prefix, Type> = {
  [Property in keyof Type as `${string & Prefix}_${string &
    Property}`]: Type[Property];
};

type LocalEnvVars = EnvVar<'REACT_APP', EnvironmentVariables>;
export type APIEnvVars = EnvVar<'CONFIG', EnvironmentVariables>;

export const getLocalEnvVar = (name: keyof EnvironmentVariables) => {
  const variables = process.env as unknown as LocalEnvVars;
  const key: keyof LocalEnvVars = `REACT_APP_${name}`;
  const value = variables[key];
  if (!value) {
    // eslint-disable-next-line no-console
    console.error(`Local environment variable ${key} is not defined`);
  }
  return value;
};

export const getLocalStorageEnvVar = (name: keyof EnvironmentVariables) => {
  const value = localStorage.getItem(name);
  if (!value) {
    return null;
  }
  return value;
};

export const getAPIConfigVar = (
  variables: APIEnvVars,
  name: keyof EnvironmentVariables
) => {
  const key: keyof APIEnvVars = `CONFIG_${name}`;
  const value = variables[key];
  if (!value) {
    // eslint-disable-next-line no-console
    console.error(`Config variable ${key} is not defined`);
  }
  return value;
};

/**
 * Parse a domain specific config like
 * `"domain.com:value,otherdomain.com:otherValue"` into a map like `{
 * "domain.com": "value", "otherdomain.com": "otherValue" }`
 */
function parseDomainSpecificConfig<
  ConfigByDomain extends string = configMappedByDomain,
  configValue = string
>(configValue: ConfigByDomain) {
  const domainsWithValues = configValue.split(',');

  const domainsMap = domainsWithValues.reduce((map, domainWithValue) => {
    const domainWithValueChunks = domainWithValue.split(':');

    if (domainWithValueChunks.length < 2) {
      return map;
    }

    const value = domainWithValueChunks.pop();
    // we join the chunks again in case there was a portnumber specifies eg. localhost:3000
    const domainWithPotentialPort = domainWithValueChunks.join(':');

    // domainsMap = { domain1: value1, domain2: value2 }
    const domainMap = { ...map } as Record<string, configValue>;

    domainMap[domainWithPotentialPort] = value as configValue;

    return domainMap;
  }, {} as Record<string, configValue>);

  return domainsMap;
}

export function getConfigByDomain<
  ConfigValue extends string = string,
  ConfigDomainListType extends string = configMappedByDomain
>(domain: string, configDomainList: ConfigDomainListType): ConfigValue {
  const domainsMap = parseDomainSpecificConfig<
    ConfigDomainListType,
    ConfigValue
  >(configDomainList);

  return domainsMap[domain] || ('' as ConfigValue);
}

export const getEnvironmentVariablesFromLocal =
  (): Promise<EnvironmentVariables> => {
    const localEnvVariables: EnvironmentVariables = {
      AG_GRID_LICENSE: getLocalEnvVar('AG_GRID_LICENSE'),
      API_ENDPOINT:
        getLocalStorageEnvVar('API_ENDPOINT') || getLocalEnvVar('API_ENDPOINT'),
      CONSOLIDATED_API_ENDPOINT:
        getLocalStorageEnvVar('CONSOLIDATED_API_ENDPOINT') ||
        getLocalEnvVar('CONSOLIDATED_API_ENDPOINT'),
      CARDLAY_CLIENT_ID: getLocalEnvVar('CARDLAY_CLIENT_ID'),
      CONCUR_OAUTH_API_URL: getLocalEnvVar('CONCUR_OAUTH_API_URL'),
      LOGGING_DEBUG_ENABLED: getLocalEnvVar('LOGGING_DEBUG_ENABLED'),
      APP_NAME: getLocalEnvVar('APP_NAME'),
      LOGGING_ENABLED: getLocalEnvVar('LOGGING_ENABLED'),
      MIXPANEL_TOKEN: getLocalEnvVar('MIXPANEL_TOKEN'),
      CONCUR_CLIENT_ID: getLocalEnvVar('CONCUR_CLIENT_ID'),
      SENTRY_DSN: getLocalEnvVar('SENTRY_DSN'),
      VERSION: getLocalEnvVar('VERSION'),
      SEON_SDK_HOST: getLocalEnvVar('SEON_SDK_HOST'),
      SEON_SDK_SOURCE_URL: getLocalEnvVar('SEON_SDK_SOURCE_URL'),
      SEON_SDK_SCRIPT_ID: getLocalEnvVar('SEON_SDK_SCRIPT_ID'),
      SOLUTION: getLocalEnvVar('SOLUTION') as DomainSolutions,
      GOOGLE_MAPS_API_KEY: getLocalEnvVar(
        'GOOGLE_MAPS_API_KEY'
      ) as configMappedByDomain,
    };
    return Promise.resolve(localEnvVariables);
  };

export const getEnvironmentVariablesFromAPI = () =>
  fetch(`${window.location.origin}/app-config`)
    .then((response) => response.json() as Promise<APIEnvVars>)
    .then((apiEnvVars) => {
      const apiEnvVariables: EnvironmentVariables = {
        AG_GRID_LICENSE: getAPIConfigVar(apiEnvVars, 'AG_GRID_LICENSE'),
        API_ENDPOINT: getAPIConfigVar(apiEnvVars, 'API_ENDPOINT'),
        CONSOLIDATED_API_ENDPOINT: getAPIConfigVar(
          apiEnvVars,
          'CONSOLIDATED_API_ENDPOINT'
        ),
        APP_NAME: getAPIConfigVar(apiEnvVars, 'APP_NAME'),
        CARDLAY_CLIENT_ID: getAPIConfigVar(apiEnvVars, 'CARDLAY_CLIENT_ID'),
        CONCUR_OAUTH_API_URL: getAPIConfigVar(
          apiEnvVars,
          'CONCUR_OAUTH_API_URL'
        ),
        LOGGING_DEBUG_ENABLED: getAPIConfigVar(
          apiEnvVars,
          'LOGGING_DEBUG_ENABLED'
        ),
        LOGGING_ENABLED: getAPIConfigVar(apiEnvVars, 'LOGGING_ENABLED'),
        MIXPANEL_TOKEN: getAPIConfigVar(apiEnvVars, 'MIXPANEL_TOKEN'),
        CONCUR_CLIENT_ID: getAPIConfigVar(apiEnvVars, 'CONCUR_CLIENT_ID'),
        SENTRY_DSN: getAPIConfigVar(apiEnvVars, 'SENTRY_DSN'),
        VERSION: getAPIConfigVar(apiEnvVars, 'VERSION'),
        SEON_SDK_HOST: getAPIConfigVar(apiEnvVars, 'SEON_SDK_HOST'),
        SEON_SDK_SOURCE_URL: getAPIConfigVar(apiEnvVars, 'SEON_SDK_SOURCE_URL'),
        SEON_SDK_SCRIPT_ID: getAPIConfigVar(apiEnvVars, 'SEON_SDK_SCRIPT_ID'),
        SOLUTION: getAPIConfigVar(apiEnvVars, 'SOLUTION') as DomainSolutions,
        GOOGLE_MAPS_API_KEY: getAPIConfigVar(
          apiEnvVars,
          'GOOGLE_MAPS_API_KEY'
        ) as configMappedByDomain,
      };
      return apiEnvVariables;
    });

export const parseEnvVarsToConfigProps = (
  environmentVariables: EnvironmentVariables
) => {
  const environment = process.env.REACT_APP_ENVIRONMENT as Environment;

  const appConfig: AppConfig = {
    ...config,
    agGridLicense: environmentVariables.AG_GRID_LICENSE,
    apiBaseURL: environmentVariables.API_ENDPOINT,
    consolidatedApiBaseURL: environmentVariables.CONSOLIDATED_API_ENDPOINT,
    appName: environmentVariables.APP_NAME,
    cardlayClientId: environmentVariables.CARDLAY_CLIENT_ID,
    concurClientId: environmentVariables.CONCUR_CLIENT_ID,
    loggingDebugEnabled: environmentVariables.LOGGING_DEBUG_ENABLED === 'true',
    loggingEnabled: environmentVariables.LOGGING_ENABLED === 'true',
    mixpanelToken: environmentVariables.MIXPANEL_TOKEN,
    concurOauthBaseURL: environmentVariables.CONCUR_OAUTH_API_URL,
    sentryDsn: environmentVariables.SENTRY_DSN,
    version: environmentVariables.VERSION,
    seonSDKHost: environmentVariables.SEON_SDK_HOST,
    seonSDKSourceURL: environmentVariables.SEON_SDK_SOURCE_URL,
    seonSDKScriptId: environmentVariables.SEON_SDK_SCRIPT_ID,
    environment,
    solution: environmentVariables.SOLUTION
      ? getSolutionForThisDomain(
          window.location.host,
          environmentVariables.SOLUTION,
          environment
        )
      : 'cardlaySapconcur',
    googleMapsApiKey: environmentVariables.GOOGLE_MAPS_API_KEY
      ? getConfigByDomain(
          window.location.host,
          environmentVariables.GOOGLE_MAPS_API_KEY
        )
      : '',
  };
  return appConfig;
};
