import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'state/store';

export const availableThemes = [
  'dark',
  'light',
  'lunadis',
  'avid',
  'conduiit',
] as const;

export type AvailableTheme = (typeof availableThemes)[number];

export function isValidTheme(theme: AvailableTheme): theme is AvailableTheme {
  return availableThemes.includes(theme);
}

export type UIState = {
  isLeftMenuCollapsed: boolean;
  isProfileMenuCollapsed: boolean;
  theme: AvailableTheme;
};

export const initialState: UIState = {
  isLeftMenuCollapsed: false,
  isProfileMenuCollapsed: true,
  theme: 'light',
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    collapseLeftMenu: (state) => {
      state.isLeftMenuCollapsed = true;
    },
    expandLeftMenu: (state) => {
      state.isLeftMenuCollapsed = false;
    },
    collapseProfileMenu: (state) => {
      state.isProfileMenuCollapsed = true;
    },
    expandProfileMenu: (state) => {
      state.isProfileMenuCollapsed = false;
    },
    setTheme: (
      state,
      { payload: { theme } }: PayloadAction<{ theme: AvailableTheme }>
    ) => {
      state.theme = theme;
    },
  },
});

export const {
  collapseLeftMenu,
  expandLeftMenu,
  collapseProfileMenu,
  expandProfileMenu,
  setTheme,
} = uiSlice.actions;

export const getIsLeftMenuCollapsed = (state: Pick<RootState, 'ui'>) =>
  state.ui.isLeftMenuCollapsed;

export const getIsLeftMenuExpanded = (state: Pick<RootState, 'ui'>) =>
  !state.ui.isLeftMenuCollapsed;

export const getIsProfileMenuCollapsed = (state: Pick<RootState, 'ui'>) =>
  state.ui.isProfileMenuCollapsed;

export const getTheme = (state: Pick<RootState, 'ui'>) => state.ui.theme;

export default uiSlice.reducer;
